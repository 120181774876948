
import { alertController, IonApp, IonContent, IonIcon, IonItem, IonLabel, IonImg, IonThumbnail, IonList, IonAccordion, IonAccordionGroup, IonMenu, IonMenuToggle, IonHeader, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { pricetagsSharp, handRightOutline, handRightSharp, homeOutline, homeSharp, personSharp, helpCircleSharp, listSharp, logoUsd, listOutline, personOutline, checkmarkCircleOutline, giftOutline, giftSharp } from 'ionicons/icons';
import { useSessionStore } from "@/api/session";
import { storeToRefs } from 'pinia'

export default defineComponent({
  name: 'App',
  components: { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane, IonAccordion, IonAccordionGroup, IonHeader, IonImg, IonThumbnail },
  setup() {
    const store = useSessionStore();
    const { User, isLoggedIn } = storeToRefs(store);

    const icons:any = {
      logoUsd: logoUsd,
      listSharp: listSharp,
      homeSharp: homeSharp,
      giftSharp: giftSharp,
      pricetagsSharp: pricetagsSharp,
      homeOutline: homeOutline,
      giftOutline: giftOutline,
      personSharp: personSharp,
      listOutline: listOutline,
      personOutline: personOutline,
      helpCircleSharp: helpCircleSharp,
      handRightSharp: handRightSharp,
      handRightOutline: handRightOutline,
      checkmarkCircleOutline: checkmarkCircleOutline,
    }

		const orgPages: any = ref(false)
		const userPages: any = ref(false)
    const route = useRoute();

    const presentAlert = async () => {
      const alert = await alertController.create({
        header: 'Alert',
        subHeader: 'Important message',
        message: 'This is an alert!',
        buttons: ['OK'],
      });

      await alert.present();
    };

		const getIcon = (type:string, key:string) => {
			return icons[key];
		}
        
    return {
      orgPages,
      userPages,
      icons,
      User,
      isLoggedIn,
      presentAlert,
			getIcon,
      isSelected: (url: string) => {
        return url === route.path ? 'selected' : '';
      }
    }
  }
});
