import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";
import VueEasyLightbox from 'vue-easy-lightbox'

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/custom.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { tooltip } from '@ionited/tooltip-vue';
import { useSessionStore } from "@/api/session";
import VueCookies from 'vue-cookies'

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)

const app = createApp(App).use(IonicVue).use(pinia).use(VueCookies)
app.directive('tooltip', tooltip);

// get the store
const store = useSessionStore();

// initialize auth listener to see if we
// have a user at startup
store.getAuthenticatedSession().then(() => {
    app.use(router).use(VueEasyLightbox).mount("#app");
});