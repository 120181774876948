//@ts-nocheck
import { useSessionStore } from "@/api/session";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

/**
 * 
 * @param to 
 * @param from 
 * @param next 
 */
const authCheck = (to: any, from: any, next: any) => {
  const store = useSessionStore();
  if (store.isLoggedIn) {
    if (to.name === "login") {
      next({ name: "feed" });
    } else {
      next();
    }
  } else {
    if (to.name === "login") {
      next();
    } else {
      next({ name: "login" });
    }
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/LoginPage.vue"),
    beforeEnter: authCheck,
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    name: "create-account",
    path: "/create-account",
    component: () => import("@/views/CreateAccountPage.vue"),
  },
  {
    path: "/feed",
    name: "feed",
    component: () => import("@/views/Home.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/settings",
    component: () => import("@/views/UserProfile.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/company-settings",
    component: () => import("@/views/EmployerSettings.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/disbursements",
    component: () => import("@/views/EmployerDisbursements.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/disbursements/pay-org",
    component: () => import("@/views/CompanyOrgDonation.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/disbursements/one-off",
    component: () => import("@/views/OneOffDisbursement.vue"),
    beforeEnter: authCheck,
  },
  // {
  //   path: "/implementation",
  //   component: () => import("@/views/Implementation.vue"),
  //   beforeEnter: authCheck,
  // },
  // {
  //   path: "/start-campaign",
  //   component: () => import("@/views/StartCampaign.vue"),
  //   beforeEnter: authCheck,
  // },
	{
    path: "/start-campaign",
    component: () => import("@/views/CampaignSelect.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/my-nominations",
    component: () => import("@/views/MyNominations.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/nominate/:type",
    name: "nominate",
    component: () => import("@/views/Nominate.vue"),
    beforeEnter: authCheck,
  },
	{
    path: "/nominate-an-org/:orgid",
    name: "nominate-an-org",
    component: () => import("@/views/NominateOrg.vue"),
    beforeEnter: authCheck,
  },
	{
    path: "/admin-gift",
    name: "admin-gift",
    component: () => import("@/views/AdminNominate.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/pending-contributions",
    name: "pendingContributions",
    component: () => import("@/views/PendingContributions.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/contributions",
    name: "contributions",
    component: () => import("@/views/CurrentContributions.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/employees",
    name: "employees",
    component: () => import("@/views/Employees.vue"),
    beforeEnter: authCheck,
  },
	{
    path: "/matching-donation",
    name: "matching-donation",
    component: () => import("@/views/SubmitMatchingDonation.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/get-help",
    name: "get-help",
    component: () => import("@/views/GetHelp.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/employees/add",
    name: "add-employee",
    component: () => import("@/views/AddEmployee.vue"),
    beforeEnter: authCheck,
  },
	{
    path: "/employees/upload",
    name: "upload-employees",
    component: () => import("@/views/UploadEmployees.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/org-requests",
    name: "org-requests",
    component: () => import("@/views/OrgRequests.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("@/views/ChangePassword.vue"),
    beforeEnter: authCheck,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;