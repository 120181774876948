//@ts-nocheck
import api from './divergence';

export default class {
	static signIn(email:string, password:string) {
		return api.makeRequest('Login', { email: email, password: password });
	}
	static verifyEmail(email:string) {
		return api.makeRequest('AuthEmail', { email: email });
	}
	static confirmAccount(email:string, password:string, confirm:string, code:string) {
		return api.makeRequest('AuthCode', { email: email, password: password, confirm: confirm, code: code });
	}
	static createAccount(email:string, password:string, first:string, last:string) {
		return api.makeRequest('create-account', { login: { email: email, password: password, first: first, last: last } });
	}
	static getSession() {
		return api.makeRequest('AuthSession', {}, 'POST');
	}
	static getPointsBank(employeeID:number) {
		return api.getRequest('points-bank', { employeeid: employeeID });
	}
	static onAuthStateChanged() {
		return;
	}
	static submitMatchingDonation(donation) {
		return api.makeRequest('SubmitMatchingDonation', { donation: donation });
	}
	static deleteContributionNote(contributionID) {
		return api.makeRequest('DeleteContributionNote', { contributionID: contributionID });
	}
	static editContributionNote(contributionID, note) {
		return api.makeRequest('SaveContributionNote', { contributionID: contributionID, contributionNote: note });
	}
	static requestHelp(helpRequest) {
		return api.makeRequest('RequestHelp', { title: helpRequest.title, description: helpRequest.description });
	}
	static uploadProfileImage(newImg) {
		return api.makeRequest('UploadProfileImage', { newpic: newImg });
	}
	static changePassword(oldPass, newPass) {
		return api.makeRequest('ChangePassword', { oldPass: oldPass, newPass: newPass });
	}
	static resetPassword(code, pass) {
		return api.makeRequest('ResetPassword', { code: code, pass: pass });
	}
	static forgotPassword(email) {
		return api.makeRequest('ForgotPassword', { email: email });
	}
}
