const empty_response = {
	success: false,
	message: 'Sorry, we were unable to handle your request. Please try again later.',
	data: {}
};

const request = async function (endpoint:string, data:object={}, method='POST', headers={}) {
	headers = {'Content-Type':'application/json', ...headers};
	const response = await fetch(process.env.VUE_APP_API_URL+'ajax/'+endpoint, {
		method: method,
		credentials: 'include',
		headers: headers,
		body: JSON.stringify(data)
	});
	return response.json();
}

export default class {
	static async makeRequest(endpoint:string, data={}, method='POST', headers={}): Promise<any> {
		return request(endpoint, data, method, headers) ?? empty_response;
	}
	static async getRequest(endpoint:string, data:any={}, headers:any={}): Promise<any> {
		if(data === null) {
			data = {};
		}
		const getString = Object.keys(data).length ? '?'+new URLSearchParams(data).toString() : '';
		const response = await fetch(process.env.VUE_APP_API_URL+'ajax/'+endpoint+getString, {
			method: 'GET',
			credentials: 'include',
			headers: headers,
		});
		return response.json();
	}
	static async postRequest(endpoint:string, data={}, headers:any={}): Promise<any> {
		return request(endpoint, data, 'POST', headers) ?? empty_response;
	}
	static makeRequestSync(endpoint:string, data={}, method:any='POST', headers:any={}) {
		headers = {'Content-Type':'application/json', ...headers};
		return fetch(process.env.VUE_APP_API_URL+'ajax/'+endpoint, {
			method: method,
			credentials: 'include',
			headers: headers,
			body: JSON.stringify(data)
		});
	}
	static async uploadFile(endpoint:string, file:File, data:any): Promise<any> {
		const dat = new FormData();
		dat.append('uploadedFile', file);
		for (const [key, value] of Object.entries<string>(data)) {
			dat.append(key, value);
		}
		const response = await fetch(process.env.VUE_APP_API_URL+'ajax/'+endpoint, {
			method: 'POST',
			body: dat,
			headers:{},
			mode: 'cors'
		})
		return response.json();
	}
}