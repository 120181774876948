import { defineStore } from 'pinia';
import employeeApi from "./employee";
import { removeCookie } from 'typescript-cookie';

export interface Session {
	User: any|null;
	Error: Error|null;
}

export const useSessionStore = defineStore("sessionStore", {
	state: () => ({
		User: JSON.parse(localStorage.getItem('gs') ?? '{}'),
		Error: null,
	}),
	getters: {
		isLoggedIn: (state) => state.User && state.User.ID !== 0,
		userError: (state) => state.Error,
	},
	actions: {
		async logInUser(email: string, password: string) {
			try {
				const response = await employeeApi.signIn(email, password);
				if(!response.success) {
					return response.message ?? 'Unable to log in. Please try again later.';
				} else {
					return this.authentify(response.data);
				}
			} catch (e: any) {
				return this.handleException(e, 'Unable to log in. Please try again later.');
			}
		},
		async confirmAccount(email:string, password:string, confirm:string, code:string) {
			try {
				const response = await employeeApi.confirmAccount(email, password, confirm, code);
				if(!response.success) {
					return response.message ?? 'Unable to log in. Please try again later.';
				} else {
					return this.authentify(response.data);
				}
			} catch (e: any) {
				return this.handleException(e, 'Unable to log in. Please try again later.');
			}
		},

		async logoutUser() {
			try {
				this.cleanSession();
				removeCookie('s');
				this.User = null;
				this.Error = null;
				return true;
			} catch (e: any) {
				return this.handleException(e, null);
			}
		},

		async createAccount(email: string, password: string, first: string, last: string) {
			try {
				const user = await employeeApi.createAccount(email, password, first, last);
				if(!user) {
					throw new Error('Unable to create an account. Please try again later.');
				}
				return this.authentify(user);
			} catch (e: any) {
				return this.handleException(e, 'Unable to create an account. Please try again later.');
			}
		},
		
		async getAuthenticatedSession(clean=false) {
			try {
				const response = await employeeApi.getSession();
				if(response?.success) {
					if(response?.data) {
						return this.authentify(response.data, clean);
					}
				} else {
					throw new Error(response.message ?? 'Unable to authenticate user. Please log in again.');
				}
			} catch (e: any) {
				return this.handleException(e, null);
			}
		},

		async authentify(data:any, clean = false) {
			try {
				if(!data?.Employee) {
					this.cleanSession(); // no current user, no user provided. Just clean up and return false
					return false;
				} else {
					if(clean) {
						this.User = null;
					}
					this.User = data.Employee;
					this.User.Employer = data.Employer;
					this.User.Employer.Plan = data.Plan;
					this.User.Employer.Plan.TotalCurrentContributions = data.TotalCurrentContributions;
					this.User.Employer.employees = data.Employees;
					this.User.pointsBank = data.Bank;
					this.User.Permissions = data.Permissions;
					this.User.Routes = data.Routes;
					localStorage.setItem('gs', JSON.stringify(this.User));
					this.Error = null;
					return true;
				}
			} catch(e:any) {
				return this.handleException(e, 'Unable to authenticate user. Please log in again.');
			}
		},
		
		cleanSession() {
			localStorage.removeItem('gs');
			this.User = null;
			this.Error = null;
		},
		handleException(e:any, errMsg:string|null) {
			localStorage.removeItem('gs');
			this.User = null;
			this.Error = e;
			if(!errMsg) {
				errMsg = 'We were unable to handle your request. Please try again later.';
			}
			return errMsg + (e.message ? ' Error: ' + e.message : '');
		}
	},
});
